import React from 'react'
import VehicleSelectionForm from './Comp/VehicleSelectionForm'
// import { useSearchParams } from 'react-router-dom';
import PageBreadcrumbs from '../../../components/Common/Breadcrumbs';
import { useCancelDocketAction } from '../../../hooks/reports';
import moment from 'moment';
// import { serializeQueryStrings } from '../../../utils';
import VehiclesKMReportDownload from './Comp/VehiclesKMReportDownload';
import toast from 'react-hot-toast';
import FormatToPDFPreview from './Comp/FormatToPDF';

export default function VehicleKmMonthlyReportPage() {
    // const [searchParams, setSearchParams] = useSearchParams();
    const { handleVehiclesKmData, isKMDataLoading } = useCancelDocketAction();
    const [resultData, setResultData] = React.useState(null);


    const handleSubmit = async (values) => {
        const toastId = toast.loading('Processing...');
        const data = {
            vehicle: values.vehicle,
            from_date: moment(values?.from_date).format('YYYY-MM-DD'),
            to_date: moment(values?.to_date).format('YYYY-MM-DD'),
        }
        const res = await handleVehiclesKmData(data)
        if (res.success) {
            setResultData(res?.data ?? {})
            toast.success('File has been generated.', { id: toastId });
        } else {
            toast.error(res.detail, { id: toastId })
        }

    }
    return (
        <div>
            <PageBreadcrumbs
                title='Monthly Vehicle KM Report'
                alt='Monthly Report'
                RightComp={() =>
                    <div className='d-flex justify-content-end'>
                        {/* <Link to='/reports/vehicle/km-report' className='btn btn-primary'>Reset</Link> */}
                    </div>
                }
            />
            <hr />
            <div className='card px-4 py-4 bg-light'>
                {!resultData && <VehicleSelectionForm
                    handleSubmit={handleSubmit}
                    isKMDataLoading={isKMDataLoading}
                />}

                
                {resultData && !isKMDataLoading && <VehiclesKMReportDownload
                    // currentParams={Object.fromEntries([...searchParams])}
                    data={resultData}
                    isLoading={isKMDataLoading}
                    setResultData={setResultData}
                />}
                {resultData && !isKMDataLoading && <FormatToPDFPreview
                    // currentParams={Object.fromEntries([...searchParams])}
                    data={resultData}
                    isLoading={isKMDataLoading}
                    setResultData={setResultData}
                />}
            </div>
        </div>
    )
}

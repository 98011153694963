import React from 'react';
import { formatDateTime } from '../../../../utils/shortcuts';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useReactToPrint } from 'react-to-print';


export default function FormatToPDFPreview({ data, isLoading, setResultData }) {
    const [filename, setFilename] = React.useState(data?.title ?? 'Monthly Vehicle KM Report');
    const [notes, setNotes] = React.useState(data?.report?.map(item => item.note || ''));
    const [editMode, setEditMode] = React.useState(null);
    const [lastEdited, setLastEdited] = React.useState(null);
    const contentRef = React.useRef();

    const [hideTotalKM, setHideTotalKM] = React.useState(false);
    const [hideETEKM, setHideETEKM] = React.useState(false);

    const handleNoteChange = (index, value) => {
        const newNotes = [...notes];
        newNotes[index] = value;
        setNotes(newNotes);
        setLastEdited(index);
    };

    const handleDoubleClick = (index) => {
        setEditMode(index);
    };

    const handleBlur = () => {
        setEditMode(null);
    };

    const exportToCSV = (apiData, fileName) => {
        if (!apiData) {
            console.error('Invalid apiData format!');
            return;
        }
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const { title, report, get_vehicle } = apiData;

        // Initial report data (header rows)
        let reportData = [];

        // Append report rows
        if (Array.isArray(report)) {
            reportData = reportData.concat(
                report.map((entry, index) => ({
                    'Date': entry.date || '',
                    'Driver': `${get_vehicle.driver_name || 'N/A'} @ ${get_vehicle.driver_contact || 'N/A'}`,
                    'Start KM': entry.vehicle.start_km || 0,
                    'End KM': entry.vehicle.end_km || 0,
                    'Total KM': entry.vehicle.total_km || 0,
                    'Note': notes[index] || ''
                }))
            );
        } else {
            console.error('Invalid report data format!');
        }

        // Calculate total kilometers
        const totalKM = report.reduce((sum, entry) => sum + (entry.vehicle?.total_km || 0), 0);
        let eoe_start_km = report.length > 0 ? report[0]?.vehicle?.start_km : 0;
        if (eoe_start_km === 0) {
            for (let i = 1; i < report.length; i++) {
                if (report[i]?.vehicle?.start_km > 0) {
                    eoe_start_km = report[i].vehicle.start_km;
                    break;
                }
            }
        }

        let eoe_end_km = report.length > 0 ? report[report?.length - 1]?.vehicle?.end_km : 0;
        if (eoe_end_km === 0) {
            for (let i = report.length - 2; i >= 0; i--) {
                if (report[i]?.vehicle?.end_km > 0) {
                    eoe_end_km = report[i].vehicle.end_km;
                    break;
                }
            }
        }
        // console.log('Total KM:', totalKM);
        // console.log('EOE Start KM:', eoe_start_km);
        // console.log('EOE End KM:', eoe_end_km);
        reportData.push({
            'Date': '',
            'Driver': '',
            'Start KM': '',
            'End KM': '',
            'Total KM': '',
        });
        reportData.push({
            'Date': '',
            'Driver': '',
            'Start KM': '',
            'End KM': 'Total KM',
            'Total KM': totalKM,
        });
        reportData.push({
            'Date': '',
            'Driver': '',
            'Start KM': '',
            'End KM': 'ETE KM',
            'Total KM': eoe_end_km - eoe_start_km,
        });
        reportData.push({
            'Date': '',
            'Driver': title || '-',
            'Start KM': '',
            'End KM': '',
            'Total KM': '',
        });
        reportData.push({
            'Date': 'Vehicle No',
            'Driver': '',
            'Start KM': 'Owner Name & No',
            'End KM': '',
            'Total KM': '',
        });
        reportData.push({
            'Date': get_vehicle.vehicle_name || '-',
            'Driver': '',
            'Start KM': `${get_vehicle.owner_name || '-'} @ ${get_vehicle.owner_contact || '-'}`,
            'End KM': '',
            'Total KM': '',
        });

        // Debugging logs for data validation
        // console.log('Report Data:', reportData);
        // Generate worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(reportData);

        // Check if worksheet generation was successful
        if (!worksheet) {
            console.error('Failed to generate worksheet!');
            return;
        }

        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };

        // Write workbook to an Excel file
        try {
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
            // XLSX.writeFile(workbook, `${fileName}.xlsx`);
            // console.log(`${fileName}.xlsx generated successfully!`);
        } catch (error) {
            console.error('Error writing Excel file:', error);
        }
    };

    const handlePrint = useReactToPrint({ contentRef });


    // Calculate total kilometers and EOE end kilometers
    const totalKM = data?.report?.reduce((sum, entry) => sum + (entry.vehicle?.total_km || 0), 0) || 0;
    let eoe_start_km = data?.report?.length > 0 ? data.report[0]?.vehicle?.start_km : 0;
    if (eoe_start_km === 0) {
        for (let i = 1; i < data?.report?.length; i++) {
            if (data.report[i]?.vehicle?.start_km > 0) {
                eoe_start_km = data.report[i].vehicle.start_km;
                break;
            }
        }
    }

    let eoe_end_km = data?.report?.length > 0 ? data.report[data?.report?.length - 1]?.vehicle?.end_km : 0;
    if (eoe_end_km === 0) {
        for (let i = data?.report?.length - 2; i >= 0; i--) {
            if (data.report[i]?.vehicle?.end_km > 0) {
                eoe_end_km = data.report[i].vehicle.end_km;
                break;
            }
        }
    }
    return (
        <div>
            <div className="row card p-4">
                <div className='d-flex justify-content-between mb-2'>
                    {/* <ExportToExcel exportData={customHeadings} isDisabled={isLoading} fileName={filename} /> */}
                    <div></div>
                    <h5 className="mb-3 text-center text-dark">Preview & Edit</h5>
                    <div className='d-flex gap-2'>
                        <button onClick={(e) => exportToCSV(data, filename)} disabled={false} className='btn btn-success  btn-sm'><i className="material-icons">download</i>Download</button>
                        <button onClick={handlePrint} className='btn btn-dark  btn-sm'><i className="material-icons">print</i>Print / PDF</button>
                    </div>

                </div>
                <hr />
                <div className="modal-body" style={{ width: '100%' }} ref={contentRef}>
                    <div className='text-center'>
                        <h6 className='py-3 ' style={{ background: '#9bc1e6' }}> {filename}</h6>
                    </div>
                    <div className='py-3'>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <div className='text-center'>
                                    <img src="/assets/images/report/logo.png" alt="logo" className='img-fluid' />
                                </div>
                            </div>
                            <div className='col-sm-8'>
                                <div className=''>
                                    <table className='table table-bordered table-hover table-striped d-table text-center'>
                                        <thead >
                                            <tr>
                                                <th style={{ background: '#001e62', color: '#fff' }}>Vehicle No</th>
                                                <th style={{ background: '#001e62', color: '#fff' }}>Owner Name & No</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {/* NABANNA - WB38J4288 - MCBSPL */}
                                                    {data?.get_vehicle?.vehicle_name ?? '-'}
                                                </td>
                                                <td>
                                                    {/* Jayanta Porel @ 9002956538 */}
                                                    {data?.get_vehicle?.owner_name ?? '-'} @ {data?.get_vehicle?.owner_contact ?? '-'}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover table-striped d-table mb-0">
                            <thead >
                                <tr>
                                    <th style={{ background: 'yellow', color: '#000' }}>Date</th>
                                    <th style={{ background: 'yellow', color: '#000' }}>Driver</th>
                                    <th style={{ background: 'yellow', color: '#000' }}>Start KM</th>
                                    <th style={{ background: 'yellow', color: '#000' }}>End KM</th>
                                    <th style={{ background: 'yellow', color: '#000' }}>Total KM</th>
                                    <th width="200" style={{ background: 'yellow', color: '#000' }}>Note</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.report?.map((item, index) => (
                                    <tr
                                        key={index}
                                        onDoubleClick={() => handleDoubleClick(index)}
                                        style={{ backgroundColor: lastEdited === index ? '#e0f7fa' : 'transparent' }}
                                    >
                                        <td>{formatDateTime(item.date)}</td>
                                        <td>{data?.get_vehicle?.driver_name} @ {data?.get_vehicle?.driver_contact}</td>
                                        <td>{item.vehicle.start_km}</td>
                                        <td>{item.vehicle.end_km}</td>
                                        <td>{item.vehicle.total_km}</td>
                                        <td>
                                            {editMode === index ? (
                                                <input
                                                    type="text"
                                                    value={notes[index]}
                                                    onChange={(e) => handleNoteChange(index, e.target.value)}
                                                    onBlur={handleBlur}
                                                    autoFocus
                                                />
                                            ) : (
                                                notes[index]
                                            )}
                                        </td>
                                    </tr>
                                ))}

                                {!hideTotalKM && <tr onDoubleClick={() => setHideTotalKM(true)}>
                                    <td colSpan={3}></td>
                                    <td><span className='bold'>Total KM</span></td>
                                    <td><span className='bold'>{totalKM}</span></td>
                                    <td></td>
                                </tr>}
                                {!hideETEKM && <tr onDoubleClick={() => setHideETEKM(true)}>
                                    <td colSpan={3}></td>
                                    <td><span className='bold' title='End to End KM'>ETE KM</span></td>
                                    <td><span className='bold'>{eoe_end_km}</span></td>
                                    <td></td>
                                </tr>}
                            </tbody>
                        </table>
                        <div className=''>
                            <h5 className='py-2 text-center mb-0 border' style={{ background: '#ddd' }}>Signature</h5>
                        </div>
                        <div className="table-responsive text-center">
                            <tbale className="table table-hover table-striped d-table mb-0">
                                <thead>
                                    <th className='border px-3 py-2' style={{ color: '#000' }}>Vendor</th>
                                    <th className='border px-3 py-2' style={{ color: '#000' }}>Debashis Mitra</th>
                                    <th className='border px-3 py-2' style={{ color: '#000' }}>Niladri Chakraborty</th>
                                    <th className='border px-3 py-2' style={{ color: '#000' }}>Docket Service & Operation Team</th>
                                    <th className='border px-3 py-2' style={{ color: '#000' }}>Approval By Sir</th>
                                </thead>
                                <tbody>
                                    <td className='border px-3 py-4'>
                                        <span></span>
                                    </td>
                                    <td className='border px-3 py-4'>
                                        <span></span>
                                    </td>
                                    <td className='border px-3 py-4'>
                                        <span></span>
                                    </td>
                                    <td className='border px-3 py-4'>
                                        <span></span>
                                    </td>
                                    <td className='border px-3 py-4'>
                                        <span></span>
                                    </td>
                                </tbody>
                            </tbale>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
}
export const LIMIT_OPTIONS = [
    { 'label': '20 records', value: '20' },
    { 'label': '50 records', value: '50' },
    { 'label': '100 records', value: '100' },
    { 'label': '500 records', value: '500' },
    { 'label': '1000 records', value: '1000' },
    { 'label': '2000 records', value: '2000' },
    { 'label': '5000 records', value: '5000' },
    { 'label': 'All records', value: '100000' },
]
export const ORDERING_OPTIONS = [
    { 'label': 'ID Asc', value: 'id' },
    { 'label': 'ID Desc', value: '-id' },
    { 'label': 'Oldest First', value: 'created_at' },
    { 'label': 'Latest First', value: '-created_at' },
    { 'label': 'Oldest Updated', value: 'updated_at' },
    { 'label': 'Latest Updated', value: '-updated_at' },
]

export const DAYS_OPTIONS = [
    { 'label': 'Today', value: 'today' },
    { 'label': 'Yesterday', value: 'yesterday' },
    { 'label': 'Past 7 days', value: 'week' },
    { 'label': 'This month', value: 'month' },
    { 'label': 'This Year', value: 'year' },
]
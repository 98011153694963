import React from 'react'
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from '../../utils/shortcuts';
// import { useDocketStatics } from '../../hooks/docket/statics';

export default function DocketStatics({ data, heading, provider, isToday, docketStaticsData, isDocketStaticsLoading }) {

    let navigate = useNavigate();
    return (
        <div className="row m-1 mt-3">
            {isToday ? <>
                <h3 className='text-lg-center headerFont mb-0'>Today's Docket Statics</h3>
                <p className='text-lg-center detailsFontSmall'>{formatDateTime('today', 'Do MMMM, YYYY')} </p>
            </> : <>
                <h3 className='text-lg-center headerFont mb-0'>{heading}</h3>
                <p className='text-lg-center detailsFontSmall'>{provider?.title} Statics</p>
            </>}
            <div className="col-xl-3 pointer">
                <div className="card widget widget-stats">
                    <div className="card-body position-relative">
                        <div className="widget-stats-container d-flex">
                            <div className="widget-stats-icon widget-stats-icon-primary">
                                <i className="material-icons-outlined">push_pin</i>
                            </div>
                            <div className="widget-stats-content flex-fill" onClick={() => navigate(`/docket`)}>
                                <span className="widget-stats-title">Docket</span>
                                <span className="widget-stats-amount">{data?.total ?? '-'}</span>
                            </div>
                            <div className="widget-stats-indicator widget-stats-indicator-positive align-self-start align-rb" onClick={() => navigate(`/docket`)}>
                                <i className="material-icons">done</i> {data?.resolved ?? '-'} Resolved
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 pointer">
                <div className="card widget widget-stats">
                    <div className="card-body">
                        <div className="widget-stats-container d-flex">
                            <div className="widget-stats-icon widget-stats-icon-warning">
                                <i className="material-icons-outlined">remove_red_eye</i>
                            </div>
                            <div className="widget-stats-content flex-fill" onClick={() => navigate(`/docket?status=Pending`)}>
                                <span className="widget-stats-title">Pending</span>
                                <span className="widget-stats-amount">{data?.pending ?? '-'}</span>
                            </div>
                            {/* {data?.hire?.pending && Object.entries(data?.hire?.pending).map((arr,i ) => {
                                console.log(arr)
                                return <h5 key={i}>{arr[0]} {arr[1]}</h5>
                            })} */}
                            <div style={{ width: '55%' }}>
                                {docketStaticsData && !isDocketStaticsLoading &&
                                    Object.entries(docketStaticsData?.hirer?.pending).map((arr, i) => {
                                        return <p key={i} className='staticsText'>{arr[0]} ({arr[1]}) &nbsp;</p>
                                    })
                                }
                                {/* <p className='staticsText'>Vodafone 1 </p>
                                <p className='staticsText'>Vodafone 1 </p>
                                <p className='staticsText'>Vodafone 1 </p>
                                <p className='staticsText'>Vodafone 1 </p>
                                <p className='staticsText'>Vodafone 1 </p>
                                <p className='staticsText'>Vodafone 1 </p> */}
                            </div>
                            {/* <div className="widget-stats-indicator widget-stats-indicator-positive align-self-start">
                                <i className="material-icons">keyboard_arrow_up</i> 12%
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 pointer">
                <div className="card widget widget-stats">
                    <div className="card-body">
                        <div className="widget-stats-container d-flex">
                            <div className="widget-stats-icon widget-stats-icon-danger">
                                <i className="material-icons-outlined">back_hand</i>
                            </div>
                            <div className="widget-stats-content flex-fill" onClick={() => navigate(`/docket?status=Hold`)}>
                                <span className="widget-stats-title">Hold</span>
                                <span className="widget-stats-amount">{data?.hold ?? '-'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 pointer" >
                <div className="card widget widget-stats">
                    <div className="card-body">
                        <div className="widget-stats-container d-flex">
                            <div className="widget-stats-icon widget-stats-icon-danger">
                                <i className="material-icons-outlined">close</i>
                            </div>
                            <div className="widget-stats-content flex-fill" onClick={() => navigate(`/docket?status=Cancelled`)}>
                                <span className="widget-stats-title">Cancelled</span>
                                <span className="widget-stats-amount">{data?.cancelled ?? '-'}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

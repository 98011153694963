/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Grid } from '@mui/material';
import moment from 'moment/moment';
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import PageBreadcrumbs from '../../components/Common/Breadcrumbs';
import ExportToExcel from '../../components/Export/ExportToExcel';
import CircuitFilterModal from '../../components/Models/CircuitFilterModal';
import SortRecordsModal from '../../components/Models/SortRecordsModal';
import { useCircuitList } from '../../hooks/circuit';
import { formatDateTime } from '../../utils/shortcuts';
import providerContext from '../../context/provider/providerContext';
import Alert from '../../components/Common/Alert';
import { useUser } from '../../hooks/user';
import { CIRCUIT_LIST_COLUMNS } from '../../config';
import { getLocal, setLocal } from '../../utils/localStorage';
import { isObjectEmpty } from '../../utils';
import CircuitFilterColumnsModal from '../../components/Models/CircuitFilterColumnsModal';
import { export_circuit_columns } from '../../utils/circuit_export';
import DocketRequestModal from '../../components/LCO/DocketRequestModal';
import CircuitCounter from '../../components/Counter/CircuitCounter';
// import MobileCircuits from '../../components/MobileTables/MobileCircuits';
import DocketCreateModal from '../../components/LCO/DocketCreateModal';
import CorporateDocketCreateModal from '../../components/LCO/CorporateDocketCreateModal';
import { useDebouncedCallback } from 'use-debounce';
import CircuitsTable from './Components/CircuitsTable';

import { useCircuitStatics } from '../../hooks/circuit/statics';

export default function CircuitListPage() {
    const { user } = useUser();
    const { currentProvider } = useContext(providerContext);

    const [searchParams, setSearchParams] = useSearchParams();
    const [enableApi, setAPI] = useState(false)
    const { circuitsData, circuitsIsLoading, handleParam } = useCircuitList('', enableApi);
    const { circuitStaticsData, handleStaticsParam } = useCircuitStatics(`?provider=${currentProvider?.id}`, enableApi)

    
    const [circuitFilterModal, setCircuitFilterModal] = useState(false);
    const [circuitSortModal, setCircuitSortModal] = useState(false);
    const [docketRequestModal, setDocketRequestModal] = useState(false);
    const [corporateDocketModal, setCorporateDocketModal] = useState(false);
    const [docketRequestData, setDocketRequestData] = useState(null);
    const [exportData, setExportData] = useState([]);

    const [docketCreateModal, setCreateModal] = useState(false)
    const [currentData, setCurrentData] = useState(null)

    const fetchData = useDebouncedCallback(() => {
        const currentParams = Object.fromEntries([...searchParams]);
        let params = ''
        const limit = parseInt(currentParams?.limit ?? '20');
        const provider = parseInt(currentProvider?.id);
        if (currentParams.date_after) {
            params = new URLSearchParams({
                ...currentParams,
                date_after: moment(currentParams?.date_after).format('YYYY-MM-DD') ?? null,
                date_before: moment(currentParams?.date_before).format('YYYY-MM-DD') ?? null,
                limit,
                provider
            }).toString();
        } else {
            params = new URLSearchParams({...currentParams, limit, provider}).toString();
        }

        setAPI(true)
        params && handleParam(`?${params}`)
        handleStaticsParam(`?provider=${currentProvider?.id}`) // Get Statics
    }, 300)

    useEffect(() => {
        fetchData()

    }, [currentProvider, searchParams])
    

    //  Filter Data
    // Hide / Show Table Columns
    const [filterColumnsModal, setFilterColumnsModal] = useState(false)
    const [visibleCols, setVisibleCols] = useState(JSON.parse(getLocal(CIRCUIT_LIST_COLUMNS)) ?? {})
    useEffect(() => {
        const data = JSON.parse(getLocal(CIRCUIT_LIST_COLUMNS))
        if (!data || isObjectEmpty(data)) {
            setVisibleCols({
                circuit_type: false,
                provider: false,
                customer_id: false,
                customer_id_alt: false,
                customer_type: false,
                hirer: false,
                third_party_circuit_id: false,
                type_of_connectivity: false,
                office_name: false,

                territory_incharge: false,
                hub_incharge: false,
                fiber_vendor_team_leader: false,

                location_a: true,
                address_a: false,
                pin_a: false,
                mobile_a: false,

                location_b: true,
                mobile_b: false,
                pin_b: false,
                address_b: false,
                // area: false,
                address: false,

                // total_dockets: false,

                created_at: false,
                updated_at: true,
                status: true,
                actions: true,

                select_all: false,
            })
            setLocal(CIRCUIT_LIST_COLUMNS, JSON.stringify(visibleCols))
        }
    }, [])



    // Set Export Data
    useEffect(() => {
        if (circuitsData && !circuitsIsLoading) {
            const customHeadings = export_circuit_columns(circuitsData)
            setExportData(customHeadings)
        }
    }, [circuitsData, circuitsIsLoading])

 
    return (
        <>
            <Helmet>
                <title>Circuits - List</title>
            </Helmet>
            <PageBreadcrumbs
                title='All Circuits'
                alt='Circuits'
                RightComp={() =>
                    <div className='d-flex gap-1'>
                        {user?.permissions?.circuits?.add && <Link to='/circuit/add' className="btn btn-primary btn-style-light btn-sm"><i className="material-icons">add</i>Create</Link>}
                        {user?.permissions?.circuits?.view && <button className="btn btn-dark btn-style-light btn-sm" onClick={() => setCircuitFilterModal(true)}><i className="material-icons">filter_alt</i>Filter</button>}
                        {user?.permissions?.circuits?.view && <button className="btn btn-dark btn-style-light btn-sm" onClick={() => setCircuitSortModal(true)}><i className="material-icons">filter_list</i>Sort</button>}

                        {user?.permissions?.dockets?.view && <button className="btn btn-dark btn-style-light btn-sm" onClick={() => setFilterColumnsModal(true)}><i className="material-icons">segment</i>Visibility</button>}

                        {['EXP', 'EX'].some(role => user?.roles?.includes(role)) ? <ExportToExcel exportData={exportData} isDisabled={circuitsIsLoading} fileName={`Circuit Export - ${formatDateTime('today', 'DD-MM-YYYY')}`} /> : ''}
                    </div>
                }
            />
            <Container maxWidth="xxl" disableGutters>
                {user?.permissions?.circuits?.view && <CircuitCounter
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    // data={circuitsData?.counts}
                    data={circuitStaticsData?.counts}

                />}

                <Grid container>
                    <Grid item xs={12}>
                        {/* {circuitsIsLoading && <Loader />} */}

                        {circuitsData && !circuitsIsLoading && !circuitsData.success && <Alert
                            title='Warning!'
                            text={circuitsData.detail}
                            type='info'
                        />}


                        {/* RENDER TABLE */}
                        <div className=''>
                            {/* {circuitsData && !circuitsIsLoading && circuitsData.success && renderTableType()} */}

                            <CircuitsTable
                                circuitsData={circuitsData}
                                circuitsIsLoading={circuitsIsLoading}
                                visibleCols={visibleCols}
                                user={user}
                                setDocketRequestData={setDocketRequestData}
                                setDocketRequestModal={setDocketRequestModal}
                                setCurrentData={setCurrentData}
                                setCreateModal={setCreateModal}
                                setCorporateDocketModal={setCorporateDocketModal}
                            />
                        </div>

                    </Grid>

                    <CircuitFilterModal
                        modal={circuitFilterModal}
                        setModal={setCircuitFilterModal}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                    />
                    <CircuitFilterColumnsModal
                        modal={filterColumnsModal}
                        setModal={setFilterColumnsModal}
                        visibleCols={visibleCols}
                        setVisibleCols={setVisibleCols}
                    />
                    <SortRecordsModal
                        modal={circuitSortModal}
                        setModal={setCircuitSortModal}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        redirect='/circuit'
                    />
                    <DocketRequestModal
                        modal={docketRequestModal}
                        setModal={setDocketRequestModal}
                        currentData={docketRequestData}
                        setCurrentData={setDocketRequestData}
                    />
                    <DocketCreateModal
                        modal={docketCreateModal}
                        setModal={setCreateModal}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                    <CorporateDocketCreateModal
                        modal={corporateDocketModal}
                        setModal={setCorporateDocketModal}
                        currentData={currentData}
                        setCurrentData={setCurrentData}
                    />
                </Grid>
            </Container>
        </>
    )
}

import React from 'react'
import Modal from 'react-modal'
import { toast } from 'react-toastify';
import { useUser } from '../../../../hooks/user';
import { formatDateTime } from '../../../../utils/shortcuts';
import { secondsToHMS } from '../../../../utils';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: '700px',
        width: '100%'
    },
    overlay: { zIndex: 1000 }
};
export default function GreethingFormatModal({ modal, setModal, docData }) {
    const { user } = useUser();

    function CopyToClipboard(containerid = 'table') {
        var range = document.createRange();
        range.selectNode(document.getElementById(containerid));
        window.getSelection().removeAllRanges(range);
        window.getSelection().addRange(range);
        document.execCommand("copy");
        toast.info('Copied to clipboard');
        // alert("Text Copied to clipboard");
    }
    const renderDocketInfo = (status) => {

        return <table className='table m-0' id='table'>
            <tbody>
                <tr>
                    <td className='text-dark' colSpan={2}>
                        <p>Dear Team,</p>
                        {docData?.get_circuit?.circuit_type === 'WAN' ? <p>The docket has been closed successfully. Please share the RFO.</p> : <p>Your docket has been closed successfully. Please find the details below.</p>}
                    </td>
                </tr>
                <tr>
                    <th>Corp. Docket ID:</th>
                    <td className='text-dark'>{docData?.code}</td>
                </tr>
                <tr>
                    <th>Circuit ID:</th>
                    <td className='text-dark'>{docData?.get_circuit?.code}</td>
                </tr>
                <tr>
                    <th>Customer ID:</th>
                    <td className='text-dark'>{docData?.get_circuit?.customer_id}</td>
                </tr>
                <tr>
                    <th>3rd Party Docket No:</th>
                    <td>{docData?.third_party_docket_no || 'N/A'}</td>
                </tr>
                <tr>
                    <th>Location A (BTS-POP-HUB):</th>
                    <td>{docData?.get_circuit?.location_a}</td>
                </tr>
                <tr>
                    <th>Location B (Customer End):</th>
                    <td>{docData?.get_circuit?.location_b}</td>
                </tr>
                <tr>
                    <th>Docket Opening Time:</th>
                    <td>{formatDateTime(docData?.created_at, 'LLLL')}</td>
                </tr>
                <tr>
                    <th>Docket Closing Time:</th>
                    <td>{formatDateTime(docData?.closed_at, 'LLLL')}</td>
                </tr>
                <tr>
                    <th>RFO:</th>
                    <td>{docData?.get_closed_info?.get_rfo?.name}</td>
                </tr>
                <tr>
                    <th>Total TAT:</th>
                    <td>{secondsToHMS(docData?.get_total_tat)}</td>
                </tr>
                <tr>
                    <th>Hold TAT:</th>
                    <td>{secondsToHMS(docData?.get_hold_tat) || 'N/A'}</td>
                </tr>
                <tr>
                    <th>Wait TAT:</th>
                    <td>{secondsToHMS(docData?.get_wait_tat) || 'N/A'}</td>
                </tr>
                <tr>
                    <th>Cust. Access Issue:</th>
                    <td>{docData?.get_closed_info?.ch_hh}:{docData?.get_closed_info?.ch_mm}:{docData?.get_closed_info?.ch_ss}</td>
                </tr>
                <tr>
                    <th>Other`s Issue:</th>
                    <td>{docData?.get_closed_info?.oh_hh}:{docData?.get_closed_info?.oh_mm}:{docData?.get_closed_info?.oh_ss}</td>
                </tr>
                <tr>
                    <th>Others Hold Reason:</th>
                    <td>{docData?.get_closed_info?.others_hold_reason || 'N/A'}</td>
                </tr>
                <tr>
                    <th>Actual TAT:</th>
                    <td>{secondsToHMS(docData?.get_actual_tat) || '-'}</td>
                </tr>
                <tr>
                    <th>Confirmed By:</th>
                    <td>{docData?.get_closed_info?.confirmed_by}</td>
                </tr>
                {/* <tr>
                    <th>Docketed by:</th>
                    <td>{docData?.docketed_by} @ {docData?.docketed_by_contact ?? 'NOT AVAILABLE'}</td>
                </tr> */}
                <tr>
                    <th>Problem:</th>
                    <td className='text-dark'>{docData?.get_problem?.title ?? 'N/A'}</td>
                </tr>
                <tr>
                    <th>Status:</th>
                    <td className='text-dark'>{docData?.status} {docData?.alt_status && docData?.status === 'Pending' ? ` - ${docData?.alt_status}` : ''}</td>
                </tr>
                <tr>
                    <th>Comments:</th>
                    <td><code>Open:</code> {docData?.comment || 'N/A'} <br />
                        <code>Close:</code> {docData?.get_closed_info?.comments || 'N/A'}
                    </td>
                </tr>
            </tbody>
        </table>


    }

    return (
        <Modal
            isOpen={modal}
            // onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Corporate Docket Email Format"
        >
            <div className="container-fluid">

                <div className="d-flex justify-content-between">
                    <h5 className="modal-title mb-3 text-center text-info">Corp. Greething Format</h5>
                    <button type="button" className="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close" onClick={() => { setModal(false); }}></button>

                </div>
                <div className="modal-body" style={{ width: '100%' }}>
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div><button className='btn btn-dark' onClick={() => setModal(false)}> <i className="fa fa-times" aria-hidden="true"></i>Close</button></div>
                        <div>
                            <button className='btn btn-dark' type='button' onClick={() => { CopyToClipboard(); setModal(false); }}> <i className="fa fa-copy" aria-hidden="true"></i>Copy clipboard</button>
                        </div>
                    </div>
                    <hr />
                    {renderDocketInfo(docData.status)}
                    {/* <div className="d-flex justify-content-between align-items-center mt-3">
                        <div></div>
                        <div>
                            <button className='btn btn-dark' onClick={() => setModal(false)}> <i className="fa fa-times" aria-hidden="true"></i>Close</button>
                        </div>
                    </div> */}
                </div>
            </div>
        </Modal>
    )
}

import { useQuery } from "@tanstack/react-query";
import { API_HOST_URL } from "../../config";
import { endpoints } from "../../config/endpoints";
import { fetchJson } from "../../libs/api";
import { getTokens } from "../../utils/shortcuts";
import { useState } from "react";


const CIRCUITS_STATICS = 'circuits_count'


// Get Circuit Statics
// export function useCircuitStatics(defaultParam = '', enabled = true) {
//     const [param, setParam] = useState(null);
//     const { isLoading, data } = useQuery([CIRCUITS_STATICS, { param: param, defaultParam }], async (key) => {
//         const param = key.queryKey[1].param;
//         const defaultParam = key.queryKey[1].defaultParam;
//         try {
//             const { accessToken } = getTokens();
//             const param_url = param ? param : defaultParam;
//             const res = await fetchJson(`${API_HOST_URL}/${endpoints.circuit.static}${param_url}`, {
//                 headers: { 'Authorization': `Bearer ${accessToken}` },
//             }, true)
//             return await res.json()

//         } catch (err) {
//             return { success: false, detail: err };
//         }
//     }, {
//         cacheTime: Infinity,
//         staleTime: 30_000,
//         enabled: enabled,
//     })
//     return {
//         circuitStaticsData: data, isCircuitStaticsLoading: isLoading,
//         handleStaticsParam: async (value) => setParam(value),
//     }
// }


export function useCircuitStatics(defaultParam = '', enabled = true) {
    const [param, setParam] = useState(null);
    const { isLoading, data } = useQuery([CIRCUITS_STATICS, { param: param, defaultParam }], async (key) => {
        const param = key.queryKey[1].param;
        const defaultParam = key.queryKey[1].defaultParam;
        try {
            const { accessToken } = getTokens();
            const param_url = param ? param : defaultParam;
            const res = await fetchJson(`${API_HOST_URL}/${endpoints.circuit.static}${param_url}`, {
                headers: { 'Authorization': `Bearer ${accessToken}` },
            }, true)
            return await res.json()

        } catch (err) {
            return { success: false, detail: err };
        }
    }, {
        cacheTime: Infinity,
        staleTime: 30_000,
        enabled: enabled,
    })
    return {
        circuitStaticsData: data,
        docketStaticsIsLoading: isLoading,
        handleStaticsParam: async (value) => setParam(value),
    }
}


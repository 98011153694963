import { formatDateTime } from "../../utils/shortcuts";

export const export_vehicles_km_report_columns = (data) => {
    const excludeIds = [39, 28]; // List of IDs to exclude

    return data?.filter(item => !excludeIds.includes(item.id))?.map((item) => {
        return {
            "ID": item.id,
            "Name": item.name,
            "Start KM": item.start_km,
            "End KM": item.end_km,
            "Total KM": item.total_km,
        }
    })
}

// export const export_vehicle_monthly_km_report_columns = (data) => {

//     return data?.map((item) => {
//         return {
//             "Date": formatDateTime(item.date, 'DD-MM-YYYY'),
//             "Driver": item?.name,
//             "Start KM": item.vehicle.start_km,
//             "End KM": item.vehicle.end_km,
//             "Total KM": item.vehicle.total_km,
//         }
//     })
// }



export const export_materials_used_columns = (data) => {

    return data?.map((item) => {
        // console.log('Item ->', item)
        const materials = item?.materials
        const materials_obj = {}

        // Generating Dynamic Field and Value for Materials
        materials && materials.map((val, i) => {
            // Construct the property name in materials_obj
            // If sub_field is different from field, concatenate them
            // Otherwise, just use field
            const propName = `${val.field} ${val.field === val.sub_field ? '' : `(${val.sub_field})`}`;

            // Parse the value to an integer and assign it to the constructed property
            materials_obj[propName] = parseInt(val.value);
        });
        return {
            "Docket Id": item.code,
            "Created at": formatDateTime(item.created_at, 'DD-MM-YYYY HH:mm:ss'),
            "Closed at": formatDateTime(item?.closed_at, 'DD-MM-YYYY HH:mm:ss'),
            "Customer ID": item?.get_circuit?.customer_id,
            "Circuit ID": item.get_circuit.code,
            "Hirer": item.get_hirer?.name,
            "Problem": item?.get_problem?.title,
            "Location A": item?.get_circuit?.location_a,
            "Location B": item?.get_circuit?.location_b,

            ...materials_obj,
        }
    })
}
import React from 'react'
import { formatDateTime } from '../../../../utils/shortcuts';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";



export default function VehiclesKMReportDownload({ data, isLoading, setResultData }) {
    // const [enableFilenameEdit, setEnableFilenameEdit] = React.useState(false);
    const [filename, setFilename] = React.useState(data?.title ?? 'Monthly Vehicle KM Report');



  
    return (
        <div>
            <div className="row card mt-3">

                <div className="d-flex justify-content-center">
                    <h5 className="mb-2 mt-4 text-center text-primary">Download Vehicle KM Monthly Report</h5>
                </div>
                <div className="modal-body" style={{ width: '100%' }}>

                    <div>
                        {data?.from_date && <p>Date Range: <span className='fw-bold text-dark'>{formatDateTime(data?.from_date, 'DD-MM-YYYY')} to {formatDateTime(data?.to_date, 'DD-MM-YYYY')}</span></p>}

                        <p>Vehicle Info: {data?.get_vehicle?.vehicle_name ?? '-'}</p>
                        <p>Driver: {data?.get_vehicle?.driver_name ?? '-'} @ {data?.get_vehicle?.driver_contact ?? '-'}</p>
                        <p>Owner: {data?.get_vehicle?.owner_name ?? '-'} @ {data?.get_vehicle?.owner_contact ?? '-'}</p>
                        <p>
                        File Name: <span className='fw-bold'>{filename}</span>
                            {/* File Name: <a href="#" onClick={() => setEnableFilenameEdit(!enableFilenameEdit)}>{enableFilenameEdit ? 'save' : 'edit'}</a> */}
                        </p>
                        {/* <input type="text" className="form-control" value={filename} onChange={(e) => setFilename(e.target.value)} disabled={!enableFilenameEdit} /> */}

                    </div>
                    {/* Footer */}
                    <div className=''>
                        {!data && isLoading && <div>
                            <div className='d-flex justify-content-end gap-2'>
                                <div className="spinner-grow text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow text-secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <div className="spinner-grow text-warning" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            <p className='text-center text-warning mt-2'>Please wait, while processing data... be patient.</p>
                        </div>}

                        {/* {!isLoading && data?.report?.length > 0 && <div>
                            <div className='d-flex justify-content-center mt-5 gap-2'>

                                <button onClick={(e) => exportToCSV(data, filename)} disabled={false} className='btn btn-primary btn-style-light'><i className="material-icons">download</i>Download Now</button>

                            </div>
                            <p className='text-center text-success mt-2'>Ready to download! Thanks for your patience.</p>
                        </div>} */}

                        <div className='d-flex justify-content-end mt-5 gap-2'>
                            <button onClick={() => setResultData(null)} className='btn btn-danger btn-sm py-2 px-4'>Cancel</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

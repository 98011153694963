import { LoadingButton } from '@mui/lab'
import { Form, Formik } from 'formik'
import React from 'react'
import { formatVehicleOptionsList, useVehiclesList } from '../../../../hooks/core/vehicle';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { serializeQueryStrings } from '../../../../utils';
import moment from 'moment';
import * as Yup from 'yup'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SelectField from '../../../../components/Common/Fields/SelectField';


const animatedComponents = makeAnimated();

export default function VehicleSelectionForm({ isKMDataLoading, handleSubmit }) {
    let navigate = useNavigate();
    const { vehiclesData, vehiclesIsLoading } = useVehiclesList();
    // const currentParams = Object.fromEntries([...searchParams]);


    const validationSchema = Yup.object().shape({
        from_date: Yup.date().required('From date is required').max(Yup.ref('to_date'), 'From date cannot be after To date'),
        to_date: Yup.date().required('To date is required').min(Yup.ref('from_date'), 'To date cannot be before From date'),
        vehicle: Yup.string().required('Vehicle is required.').nullable()
    });
    const today = new Date();
    const thirtyDaysBefore = new Date();
    thirtyDaysBefore.setDate(today.getDate() - 30);
    return (
        <Formik
            initialValues={{
                from_date: thirtyDaysBefore, // 30 days before
                to_date: today, // today date
                vehicle: null
            }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
        >
            {formik => (
                <Form>
                    <div className='row'>
                        <div className="col-md-12 mb-4">
                            {formik.errors.vehicle && <div className="text-danger">{formik.errors.vehicle}</div>}
                            <SelectField
                                name='vehicle'
                                options={vehiclesData?.data.map(formatVehicleOptionsList)}
                                value={vehiclesData?.data.map(formatVehicleOptionsList).filter(function (option) {
                                    return option.value === formik.values.vehicle;
                                })}
                                onChange={(e) => formik.setFieldValue('vehicle', e.value)}
                                isLoading={vehiclesIsLoading}
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 mb-4">
                            <label htmlFor="from_date">From Date</label>
                            <Datetime
                                value={formik.values.from_date}
                                onChange={date => formik.setFieldValue('from_date', date)}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                inputProps={{ placeholder: 'Select start date' }}
                            />
                            {formik.errors.from_date && <div className="text-danger">{formik.errors.from_date}</div>}
                        </div>
                        <div className="col-md-6 mb-4">
                            <label htmlFor="to_date">To Date</label>
                            <Datetime
                                value={formik.values.to_date}
                                onChange={date => formik.setFieldValue('to_date', date)}
                                dateFormat="YYYY-MM-DD"
                                timeFormat={false}
                                inputProps={{ placeholder: 'Select end date' }}
                            />
                            {formik.errors.to_date && <div className="text-danger">{formik.errors.to_date}</div>}
                        </div>
                    </div>



                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <LoadingButton
                                loading={isKMDataLoading}
                                type="submit"
                                variant="contained"
                                disableElevation
                            >
                                Get Report
                            </LoadingButton>

                            <Button variant="outlined" sx={{ ml: 1 }} disableElevation
                                onClick={() => {
                                    navigate(`/reports/vehicle/km-report-monthly`);
                                }}
                            >Reset</Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}